<template>
  <div>
    <v-snackbar
      elevation="10"
      v-for="(item, i) in items"
      :color="item.color"
      :key="item.id"
      :timeout="item.timeout"
      :value="i === 0"
    >
      <v-layout align-center>
        <v-icon class="mr-3" v-if="item.icon">{{ item.icon }}</v-icon>
        <p class="my-0" v-html="item.message"></p>
      </v-layout>
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="removeItem(item)">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "SnackbarQueue",
  data: () => ({
    timeoutId: false,
  }),
  computed: {
    items() {
      return this.$store.state.snackbar.items;
    },
  },
  created() {
    this.processItems();
  },
  methods: {
    processItems() {
      if (this.items.length > 0) {
        const item = this.items[0];
        this.timeoutId = setTimeout(() => {
          this.removeItem(item);
        }, item.timeout);
      }
    },
    removeItem(item) {
      clearTimeout(this.timeoutId);
      this.$store.commit("snackbar/remove", item.id);
    },
  },
  watch: {
    items() {
      this.processItems();
    },
  },
};
</script>
